<template>
    <div class="dF fC f1 px-4 pb-4 hide-scrollbar" style="background:var(--light-bg-gray); overflow-y:scroll">
        <FileList :files="currentFiles" v-if="currentFiles.length"/>
        <EmptyApp :text="'You do not have any files'" :app="$store.state.theApp"  v-else />
    </div>
</template>

<script>
    import FileList from '@/components/file/FileList'
    import EmptyApp from 'bh-mod/components/common/EmptyApp'
    export default {
        components:{
            EmptyApp,
            FileList,
        },
        data() {
            return {
            }
        },
        watch:{
        },
        computed: {
            instance() {
                return this.$store.state.instance
            },
            files(){
                return this.$store.state.files
            },
            currentFiles(){
                return this.$store.getters.currentFiles
            },
        },
        methods:{

        },
        created() {
            this.$store.commit('PAGE_BUTTONS', [
                {
                    label:'UPLOAD',
                    fe:'fe-upload-cloud mr-2',
                    callback: () => this.$store.commit('uploadDialog')
                }
            ])
			this.$store.dispatch('GET_FILE_STRUCTURE');
        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style>
    .cancel-button.ant-btn {
        border-color:#ECE9F1 !important;
        background-color:#ECE9F1;
        color:#3F3356
    }
</style>
<style lang="scss">
    .hive-cat-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }

    .app-store-tabs {
        .ant-tabs-bar {
            background: white;
            padding: 10px 100px 20px
        }
    }
    .file-list-preview{
        .ant-card{
            position: relative;
            &:hover{
                [aria-label="icon: close"]{
                    opacity:1;
                    transform:translate3d(-50%,0,0);
                }
                .ant-card-body{
                    background:var(--primary-vl);
                }
            }
        }
        .ant-card-body{
            padding:10px 5px 8px;
        }
        .ant-card-meta-title{
            text-align: center;
            font-size:12px;
        }
        [aria-label="icon: close"]{
            opacity:0;
            transition:opacity .3s ease-out, transform .3s ease-out;;
            position:absolute;
            left:50%;
            top:25px;
            transform:translate3d(-50%,-70%,0);
            background: white;
            padding: 5px;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 0 2px 5px rgba(50,50,50,0.5);
            &:hover{
                background:var(--primary);
                color:white;
            }
        }
    }
    .list-item {
        display: inline-block;
        margin-right: 10px;
        border: 1px solid var(--off-white-dark);
        padding: 10px;
    }
    .list-enter-active, .list-leave-active {
        transition: all .5s;
    }
    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: scale(0);
    }

</style>
