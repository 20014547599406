<template>
    <div>
        <BhLoading :show="loading" />

        <div class="dF jSB">

            <h4 class="pb-2" >{{$store.state.currentFolder !== '/' ? $store.state.currentFolder : 'Main'}}</h4>
            <MoreActions v-if="selectedRowKeys.length > 1" shape="button" buttonText="Bulk Actions" :items="bulkActions" @action="bulkActioned"/>

        </div>
        <a-modal
            :visible="visible"
            centered
            :footer="null"
            @cancel="visible =! visible"
            :width="578"
            >

            <div class="w-full relative">
                <img :src="previewImage" style="max-width:530px; max-height:700px; min-width: 265px; object-fit:cover; transform: translateX(-50%); left: 50%; position: relative;"/>
            </div>
        </a-modal>

        <a-modal
            :visible="link"
            centered
            :footer="null"
            :width="600"
            @cancel="link =! link"
            >
            <h4>File Link</h4>
            <a-input read-only v-model="shareLink" id="myInput">

                <a-tooltip overlayClassName="change-tooltip-color" slot="prefix" title="Copy link to Clipboard">
                    <a-icon @click="copyLink" type="link" />
                </a-tooltip>

            </a-input>

            <div class="mt-3" style="text-align:end">
                <a-button size="large" type="primary" @click="link =! link">DONE</a-button>
            </div>
        </a-modal>
        <a-modal
            :visible="move"
            centered
            :footer="null"

            @cancel="closeMoveModal"
            >
            <BhLoading :show="moveLoading" />
            <div>
                <h4 style="margin-bottom:0">Move File</h4>
                <p style="margin-bottom:0!important" v-if="selectedRowKeys.length == 0 && Object.keys(moveObj).length">Move {{moveObj.name}} to a different folder</p>
                <p style="margin-bottom:0!important" v-if="selectedRowKeys.length != 0">Move the selected {{selectedRowKeys.length}} files to a different folder</p>
                <p class="mt-3">Choose Folder</p>
                <a-form>
					<a-form-item>
						<a-tree-select
							v-model="chosenFolder"
							show-search
							style="width: 100%"
							:dropdown-style="{ maxHeight: '500px', overflow: 'auto' }"
							:tree-data="treeData"
							:replaceFields="{
								children:'children', title:'title', key:'value', value: 'value'
							}"
							placeholder="Please select"
						>
						</a-tree-select>
					</a-form-item>
					<a-form-item v-if="chosenFolder.includes('__newFolder')" :validate-status="newFolderError? 'error' : ''"
							:help="newFolderError? 'Should be combination of numbers & alphabets & spaces and underscores' : ''">
						<a-input
							placeholder="Enter folder name"
							v-model="newFolder"
							:addon-before="`${chosenFolder.replace('__newFolder', '')}`"
						>
						</a-input>
					</a-form-item>
                    <div style="text-align:end">
                        <a-button @click="move =! move" class="mr-3 cancel-button" size="large">CANCEL</a-button>
                        <a-button @click="moveFile" type="primary" size="large">MOVE</a-button>
                    </div>

                </a-form>
            </div>
        </a-modal>
        <a-modal
            :visible="rename"
            centered
            :footer="null"
            @cancel="rename =! rename"
            >
            <h4>Rename</h4>
            <a-form>
				<a-form-item v-if="renameObj.Type === 'Folder'">
                    <a-input v-model="newName" :placeholder="renameObj.name" :addon-before="`${this.currentFolder}`"></a-input>
                </a-form-item>
                <a-form-item v-else>
                    <a-input v-model="newName" :placeholder="renameObj.name"></a-input>
                </a-form-item>
            </a-form>
            <div style="text-align:end">
                <a-button @click="rename =! rename" class="mr-3 cancel-button" size="large">CANCEL</a-button>
                <a-button @click="renameFile" type="primary" size="large">CONFIRM</a-button>
            </div>
        </a-modal>
        <a-table :rowKey="e => e.id" :row-selection="rowSelection" :columns="columns" :data-source="files" class="white-table" >
            <template slot="description" slot-scope="record" >
                <span v-if="record.description"></span>
                <span v-else class="text-med-gray">
                    No Description...
                </span>

            </template>
            <template slot="name" slot-scope="record" >
                <div @click="showPreview(record)" style="cursor:pointer" class="fw-500 dF aC">
                   <a-icon class="mr-3" :type="record.Icon.icon" :style="`color:${record.Icon.color}`" style="width: 32px; height: 32px; font-size: 32px;" v-if="record.Type !== 'Image'"/>
                    <a-avatar v-else class="mr-3" :src="record.url && record.url.includes('https')? record.url:`https://${record.url}`"></a-avatar>
                    {{record.name}}
                </div>
            </template>
            <template slot="fileSize" slot-scope="record" >
				<span v-if="record.size">{{record.size}}KB</span>
            </template>

            <template slot="type" slot-scope="record">
                {{record.Type}}
            </template>

            <template slot="action" slot-scope="record" >
                <MoreActions :items="moreActions" :val="record" @action="action"/>
            </template>
        </a-table>
    </div>

</template>

<script>
// import Table from 'bh-mod/components/common/Table'
import MoreActions from 'bh-mod/components/common/MoreActions'
import {formatDate, downloadURL} from 'bh-mod'
import BhLoading from 'bh-mod/components/common/Loading'
const FileSaver = require('file-saver')
const JSZip = require('jszip')
import axios from 'axios'

export default {
    props:{
        files:{
            type:Array,
            default:() => []
        },
    },

    components:{MoreActions,BhLoading},
    data(){
        return {
            loading:false,
            moveLoading:false,
            downloadURL:'',
            shareLink:'',
            link:false,
            renameObj:{},
            newName:'',
            rename:false,
            moveObj:{},
            newFolder:'',
            newFolderError:false,
            chosenFolder:'/',
            visible:false,
            previewImage:'',
            move:false,
            fileIcons:{
                'Image':{
                    icon:'file-image',
                    color:'black'
                },
                'Document':{
                    icon:'file-pdf',
                    color: 'green',
                },
                'Video':{
                    icon:'video-camera',
                    color: 'red',
                },
                'Unknown Type':{
                    icon:'file',
                    color:"yellow"
                },
                'Compressed File':{
                    icon:'file',
                    color:"yellow"
                },
            },
            bulkActions:[
                {
                    label:'Move to',
                    value:'moveFolder'
                },
                {
                    label:'Delete',
                    value:'deleteFolder'
                }
            ],
            selectedRowKeys:[],
            selectedRows:[],
            moreActions:[
                {
                    label:'Preview',
                    value:'preview',
                    icon:'fe-eye',
                },
                {
                    label:'Move to',
                    value:'move',
                    icon:'fe-send'
                },
                {
                    label:'Rename',
                    value:'rename',
                    icon:'fe-edit'
                },
                {
                    label:'Get Shareable Link',
                    value:'share',
                    icon:'fe-link'
                },
                {
                    label:'Download',
                    value:'download',
                    icon:'fe-download'
                },
                {
                    label:'Delete',
                    value:'delete',
                    icon:'fe-trash'
                },
            ],
            columns: [
                {
                    title: 'Name',
                    key: 'name',
                    scopedSlots: { customRender: 'name' },
                },
                {
                    title: 'Type',
                    key: 'type',
                    scopedSlots: { customRender: 'type' },
                },
                {
                    title: 'File Size',
                    key: 'fileSize',
                    scopedSlots: { customRender: 'fileSize' },
                },
                {
                    title: 'Action',
                    key: 'action',
                    scopedSlots: { customRender: 'action' },
                },
            ]
        }
    },
    watch:{
        newFolder(path) {
            if (!path.match(/^[a-zA-Z0-9 _]*$/)) {
                this.newFolderError = true
            } else this.newFolderError = false
        }
    },
    computed: {
		treeData() {
			return this.$store.state.fileStructure
		},
        fileObj() {
            return this.$store.getters.fileObj
        },
        instance() {
            return this.$store.state.instance
        },
        folders(){
            return this.$store.getters.folders
        },
        currentFolder() {
            return this.$store.state.currentFolder
        },
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: this.onSelectChange,
                hideDefaultSelections: true,
                onSelection: this.onSelection,

                getCheckboxProps: record => ({
                    props: {
                        disabled: record.name === 'Disabled User', // Column configuration not to be checked
                        name: record.name,
                    },
            }),
            }
        },
		allFiles() {
            return this.$store.getters.allFiles;
        },
    },
    methods:{
        closeMoveModal() {
            this.move = false
            this.chosenFolder = '/'
        },
        showPreview(item){
			if(item.Type === 'Folder'){
				this.$store.commit("changeFolder", this.currentFolder+ '/' + item.name);
			}
            else if(item.mime.includes('image')) {
                if (!item.url.includes('https')) {
                    item.url = "https://" + item.url
                }
                this.visible = true
                this.previewImage = item.url
            } else if (item.mime.includes('pdf')) {
                window.open(item.url)
            }
        },
        formatDate,
        copyLink() {
            var copyText = document.getElementById("myInput");

            copyText.select();
            copyText.setSelectionRange(0, copyText.value.length)
            document.execCommand("copy");
            this.$message.success('Link Copied!')
        },
        renameFile() {
            if (!this.newName.trim()) return this.$message.error('Name Field is empty')
			if(this.renameObj.Type === 'Folder') {
				this.$api.put(`/upload/files/renameFolder/${this.instance.id}`, {from: (this.currentFolder + '/' + this.renameObj.name), to: (this.currentFolder + '/' + this.newName),}).then( ({data}) => {
					this.$store.dispatch('GET_FILE_STRUCTURE');
					this.loading = false;
					this.rename = false
					this.renameObj = {}
					this.newName = ''
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
			} else {
				this.renameObj.name = this.newName
				this.loading = true
				this.$api.put(`/upload/files/${this.renameObj.id}?instance=${this.instance.id}`, this.renameObj).then( ({data}) => {
					this.loading = false
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
				this.rename = false
				this.renameObj = {}
				this.newName = ''
			}
        },
        moveFile() {
            let folder = this.chosenFolder
			if (folder.includes('__newFolder')){
                folder = this.chosenFolder.replace('__newFolder', this.newFolder)
				if (!folder.trim()) {
					this.newFolderError = true;
					return this.$message.error('No Folder name found. Please add folder name first.')
				}
			}

            this.moveObj.folder = folder
            if (folder == this.currentFolder) return this.$message.error('File already existed in this folder')

			if (this.moveObj.Type == 'Folder') {
				this.$store.commit("LOAD", true);
				const currentPath = this.currentFolder +'/'+ this.moveObj.name;
				const newPath = folder === '/' ? folder + this.moveObj.name : folder+'/'+ this.moveObj.name;
				this.$api.post(`/upload/files/move-folder`, {instanceId: this.instance.id, newPath, currentPath}).then(() => {
					this.$store.dispatch('GET_FILE_STRUCTURE');
					this.move = false
                    this.chosenFolder = '/'
                    this.newFolder = ''
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				}).finally(() => {
					this.$store.commit("LOAD", false);
				})
			} else if (this.selectedRowKeys.length == 0) {
                this.moveLoading = true
                this.$api.put(`/upload/files/move/${this.moveObj.id}`, {folder}).then( ({data}) => {
                let newFolder
                for (var i=0; i<data.folder.length; i++) {
                    if (data.folder.charAt(i) == '/') {
                        let index = i
                        newFolder = data.folder.substring(index+1 , data.folder.length)
                    }
                }
                if (newFolder == '') newFolder = '/'
                data.folder = newFolder
                this.$store.commit('MOVE_FILES', {file:data, folder:newFolder})
				this.$store.dispatch('GET_FILE_STRUCTURE');
                this.move = false
                this.chosenFolder = '/'
                this.newFolder = ''
                this.moveLoading = false
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            } else {
				const filesToMove = this.selectedRowKeys.filter(id => typeof id === 'string');
				if(filesToMove.length !== this.selectedRowKeys.length){
					this.$message.error('Bulk Folder move is not possible at the moment. Please select files only.')
				}
				if(!filesToMove.length){
					this.move = false
                    this.chosenFolder = '/'
                    this.newFolder = ''
                    this.selectedRowKeys = []
					return this.$message.error('No valid files found for move. Please select valid files only.')
				}
                this.moveLoading = true;
                this.$api.put(`/upload/files/bulkMove/:instance`, {folder:folder, ids:filesToMove}).then(({data}) => {
                    if (data.success) {
                        let filesArray = []
                        filesToMove.forEach(id => {
                            if (this.fileObj[id]) filesArray.push(this.fileObj[id])
                        })
                        this.$store.commit('BULK_MOVE', {files:filesArray, folder:folder})
						this.$store.dispatch('GET_FILE_STRUCTURE');
                        this.moveLoading = false
                    }
                    else {
                        this.moveLoading = false
                        return this.$message.error('Something went wrong')
                    }
                    this.move = false
                    this.chosenFolder = '/'
                    this.newFolder = ''
                    this.selectedRowKeys = []
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            }
        },

        async bulkActioned({key}){
            let self = this
            if (key === 'deleteFolder'){
                if (this.$p < 20) return this.$message.error('You do not have access to delete files')
                let self = this
                this.$confirm({
                    title: "Delete File(s)",
                    content: h => <div>Do you want to delete the selected files?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.loading = true;
						let filesToDelete = [];

						self.selectedRowKeys.forEach(id => {
							if(typeof id === 'string'){
								filesToDelete.push(id)
							} else {
								let folder = self.files[id] && self.files[id].name || '';
								self.allFiles.forEach((file) => {
									if (file.folder && file.folder.startsWith('/' + self.currentFolder+ '/' + folder)) {
										filesToDelete.push(file.id);
									}
								});
							}
						})

                        self.$api.put(`/upload/files/bulk/:instance`, filesToDelete).then(({data}) => {
                            if (data.success) {
								self.$store.commit('BULK_DELETE', filesToDelete)
                                self.selectedRowKeys = []
                                self.loading = false
								self.$store.dispatch('GET_FILE_STRUCTURE');
								self.$store.commit("changeFolder", '/');
                            }
                            else {
                                self.selectedRowKeys = []
                                self.loading = false
                                return self.$message.error('Something went wrong')
                            }
						}).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                })
            }
            if (key === 'moveFolder') {
                this.move = true
            }
        },
        async action(e){
            if (e.key === 'preview'){
				if (e.val.Type == 'Folder') {
					return this.$store.commit("changeFolder", this.currentFolder+ '/' + e.val.name);
				}
                if (e.val.Type == 'Image') {
                    if (!e.val.url.includes('https')) {
                        e.val.url = "https://" + e.val.url
                    }
                    this.visible = true
                    this.previewImage = e.val.url
                }
                else {
                    window.open(e.val.url)
                }
            } else if (e.key === 'delete'){
                let self = this
                if (this.$p < 40) return this.$message.error('You do not have access to delete files')
				if (e.val.Type == 'Folder') {
					let deleteArray = [];

					this.allFiles.forEach((file) => {
						if (file.folder && file.folder.startsWith('/' + this.currentFolder+ '/' + e.val.name)) {
							deleteArray.push(file);
						}
					});

					let self = this;
					this.$confirm({
						title: "Delete Folder",
						content: (h) => (
							<div>Do you want to delete this folder?</div>
						),
						okText: "Delete",
						okType: "danger",
						cancelText: "Cancel",
						centered: true,
						onOk() {
							let ids = deleteArray.map((x) => x.id);
							self.$store.commit("LOAD", true);
							self.$api
								.put(`/upload/files/bulk/:instance`, ids)
								.then(({ data }) => {
									if (data.success) {
										self.$store.commit("LOAD", false)
										self.$store.dispatch('GET_FILE_STRUCTURE');
										self.$store.commit("changeFolder", '/');
									} else {
										self.$store.commit("LOAD", true);
										return self.$message.error(
											"Something went wrong"
										);
									}
								}).catch(err => {
									if (!err || !err.response || !err.response.status || err.response.status !== 400) {
										self.$message.error(self.$err(err))
									}
								});
						},
						class: "test",
					});
					return;
				}
                this.$confirm({
                    title: "Delete File",
                    content: h => <div>Do you want to delete this file?</div>,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    centered: true,
                    onOk() {
                        self.loading = true
                        self.$api.delete(`/upload/files/${e.val.id}?instance=${self.instance.id}`).then( ({data}) => {
                            self.$store.commit('DELETE_FILE', data)
                            self.loading = false
                        }).catch(err => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err))
							}
						})
                    },
                })
            } else if (e.key === 'move') {
                this.move = true
                this.moveObj = e.val
            } else if (e.key === 'rename') {
                this.rename = true
                this.renameObj = e.val
            } else if (e.key === 'share') {
				if (e.val.Type == 'Folder') {
					return this.$message.error('You can\'t get shareable link for the folder. Please select file for it.')
				}
                this.link = true
                this.shareLink = e.val.url || ''
            } else if (e.key === 'download') {
				if (e.val.Type == 'Folder') {
					this.loading = true
					let filesToDownload = [];

					this.allFiles.forEach((file) => {
						if (file.folder && file.folder.startsWith('/' + this.currentFolder+ '/' + e.val.name)) {
							filesToDownload.push({name: file.name, url: file.url});
						}
					});
					this.downloadAll(filesToDownload, e.val.name);
					this.loading = false;
					return;
				}
				try {
					await downloadURL(e.val.url, e.val.name)
				} catch (err) {
					console.error('There was an error downloading the file. Please try again', err)
					this.$message.error(
						(err.response &&
							err.response.data &&
							err.response.data.message) ||
						'There was an error downloading the file. Please try again'
					);
				}
            }
        },

        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },

		downloadAll(filesToDownload, folderName){
			const zip = new JSZip();
			const arrOfFiles = filesToDownload.map((file) => {
				return axios.get(file.url, { responseType: "blob" }).then((resp) => {
					zip.file(file.name, resp.data);
				});
			});
			//create array of promises
			Promise.all(arrOfFiles)
				.then(() => {
					//when all promises resolved - save zip file
					zip.generateAsync({ type: "blob" }).then(function (blob) {
						FileSaver.saveAs(blob, `${folderName}.zip`);
					});
				})
				.catch((err) => {
					console.error('error while downloading folder', err);
				});
		}
    },
    mounted(){
        window.FileSaver = FileSaver    },
    created(){

    }
}
</script>

<style>
    .cancel-button.ant-btn {
        border-color:#ECE9F1 !important;
        background-color:#ECE9F1;
        color:#3F3356
    }
</style>
